import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { apiUrl } from '../data';

export const appAxios = axios.create({
  baseURL: apiUrl,
});

export const setBaseUrl = (baseURL: string) => {
  appAxios.defaults.baseURL = baseURL;
};

export const getImageModal = async (displayedAt: 'ingredient' | 'auth' | 'ranking' | 'grade') => {
  const options: AxiosRequestConfig = {
    url: `/v2/app-img`,
    method: 'GET',
    params: { displayedAt },
  };

  const result: AxiosResponse<any> = await appAxios.request(options);
  return result.data.data;
};
