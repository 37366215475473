import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { appAxios } from './commom';

export const apiProductCategoriesV2 = async () => {
  const options: AxiosRequestConfig = {
    url: '/v3/categories',
    method: 'GET',
  };

  try {
    const result: AxiosResponse = await appAxios.request(options);
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * 랭킹카테고리V2
 */
export const apiRankingCategoriesV2 = async () => {
  const options: AxiosRequestConfig = {
    url: '/v2/commons/ranking-category-ids',
    method: 'GET',
  };

  try {
    const result = await appAxios.request(options);
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const apiGetBrandCategory = async (brandId: number) => {
  const options: AxiosRequestConfig = {
    url: `/v3/brands/${brandId}/categories`,
    method: 'GET',
  };

  try {
    const result = await appAxios.request(options);
    return result.data.data;
  } catch (error) {
    throw error;
  }
};
